<template>
  <div class="season-pass-form relative text-center mb-4 md:my-8">
    <form :class="content && content.cssClass" class="relative contact" v-on:submit.prevent="submitForm" v-animate-onscroll="'animated fadeIn'">
      <h3 :class="`${false ? 'opacity-0 delay-200' : ''}`" v-animate-onscroll="'animated fadeIn'" v-text="`Register Your ${season} Season Pass`"/>
      <div class="px-6 py-6 pb-8 mt-2 bg-white sm:px-10">
        <h2 class="mb-6 text-xl font-bold uppercase">Card Registration</h2>
        <div class="flex flex-wrap items-start justify-start mb-6 search">
          <div class="w-full">
            <input type="text" id="cardNumber" name="cardNumber" :placeholder="`${season} Season Pass Card Number`" v-model="cardNumber" />
            <p class="mt-1 mb-3 text-xs">Limit of 10 cards on one registration form: ({{ cards.length }}/10)</p>
          </div>
          <button type="button" class="bg-white button hover:bg-purple text-purple" :class="cards.length > 9 ? '' : 'pulse'" @click="addCard" :disabled="cards.length > 9">{{ cards.length > 0 ? 'Add Another Card' : 'Apply'}}</button>
          <p v-if="entryError" class="w-full text-sm text-red" v-html="entryError"/>
        </div>
        <div class="flex items-center justify-between w-full px-4 py-2 my-1 cards" v-for="(card, i) in cards" :key="i">
          <p class="font-bold text-white uppercase" v-html="card"/>
          <svg @click="removeCard(card)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
            <path fill="#fff" d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </div>
        <h2 class="my-6 text-xl font-bold uppercase">Guest Info</h2>
        <div class="flex flex-wrap">
          <div v-for="f in fields" :key="f.index" :class="`relative mb-6 sm:mb-8 ${getWidth(f)} ${errors.indexOf(f.name) > -1 ? 'error' : ''} ${f.showKey && f.showValue.indexOf(model[f.showKey]) < 0 ? 'hidden' : ''}`" :data-error="f.errorMsg">
            <div v-if="!f.showKey || f.showValue.indexOf(model[f.showKey]) > -1">
              <label :for="f.name" :class="`main-label`" v-html="f.label" />
              <div v-if="f.type == 'date'">
                <date-field :field="f" :model="model[f.name]" :handleUpdate="onCalendarChange" />
              </div>
              <div v-else-if="f.type == 'select'">
                <mobile-select v-if="mobile" :field="f" :model="model" :handleUpdate="updateSelect" />
                <default-select v-else :field="f" :model="model" :handleUpdate="updateSelect" />
              </div>
              <div v-else-if="f.type == 'checkbox'" class="flex">
                <input class="cursor-pointer" type="checkbox" :id="f.name" :name="f.name" v-model="model[f.name]" />
                <label :for="f.name" v-html="f.label" />
              </div>
              <input v-else :type="f.type" :id="f.name" :name="f.name" :placeholder="f.label" v-model="model[f.name]" />
            </div>
          </div>
        </div>

        <div v-if="sending">
          <loader color="purple" />
        </div>
        <div v-else-if="complete" class="text-center">
          <div>Thank You!<br/>A member of our team will be in touch with you shortly.</div>
        </div>
        <div v-else-if="submitError">
          <button type="submit" class="pulse purple">Submit</button>
          <div class="text-red">There was no season pass found with that card number, please try again.</div>
        </div>
        <div v-else>
          <button type="submit" class="pulse purple">Submit</button>
        </div>
      </div>
    </form>
    <div v-if="complete && (valid || invalid)" class="fixed inset-0 flex items-center justify-center confirmation-overlay z-8">
      <div class="flex flex-col items-center justify-start w-full max-w-xl p-4 mx-4 bg-white md:p-16">
        <img src="https://images.ctfassets.net/3dar4x4x74wk/1HuwBJKXlWoiSCB21St6g8/8be41d72ddb6e8a1cbee3867db2c4c2e/logo.svg" alt="Main Event Logo">
        <div v-if="valid && valid.length" class="flex flex-col items-center justify-center">
          <h1 class="mt-4 mb-8 text-4xl uppercase font-pie md:text-6xl md:mb-0">Awesome!</h1>
          <div v-for="(card, i) in valid" :key="i" class="flex flex-wrap items-center justify-start w-full mb-2 sm:w-3/4">
            <p class="p-4 uppercase sm:w-2/3 pass">Pass # {{ card.passNumber }}</p>
            <p class="ml-3 text-sm font-bold text-left sm:w-1/4 font-decimal" :class="card.status" v-html="'Successfull'"/>
          </div>
          <p class="mt-4 mb-8 text-sm font-decimal">Your pass is now active and you’ll be able to use it at your local Main Event.</p>
        </div>
        <div v-if="invalid && invalid.length" class="flex flex-col items-center justify-center mb-8">
          <h1 class="mt-4 mb-8 text-4xl uppercase font-pie md:text-6xl md:mb-0">Oops!</h1>
          <div v-for="(card, i) in invalid" :key="i" class="flex flex-wrap items-center justify-start w-full mb-1 sm:w-3/4">
            <p class="p-4 uppercase sm:w-2/3 pass">Pass # {{ card.passNumber }}</p>
            <p class="ml-3 text-sm font-bold text-left sm:w-1/4 font-decimal" :class="card.status" v-html="'Invalid!'"/>
            <p class="w-full mt-1 mb-2 text-xs font-decimal text-red" v-html="card.memberId === '' ? 'Invalid Season Pass number, please try again.' : 'This Season Pass has already been registered.'"/>
          </div>
        </div>
        <button class="px-8 py-4 text-white bg-black pulse" @click="complete = false">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { detect } from 'detect-browser'

import Loader from './Loader'
import DefaultSelect from '../forms/Select'
import MobileSelect from '../forms/MobileSelect'
import DateField from '../forms/DateField'
const states = require('~/assets/config/states.json')
const contactFields = require('~/assets/config/seasonPassForm.json')

export default {
  components: {
    Loader,
    DefaultSelect,
    MobileSelect,
    DateField
  },
  props: {
    content: {
      type: Object,
    },
    animate: {
      type: Boolean
    },
    defaults: {
      type: Object
    },
    formName: {
      type: String
    },
    sendKey: {
      type: String
    },
    formData: {
      type: Array
    },
    location: {
      type: String
    }
  },
  data: function() {
    let fields = []
    let model = {}

    let formFields = this.formData || contactFields
    formFields.map(f => {
      if(f.name == 'Location') {
        let stateOpts = {}
        Object.keys(this.$store.state.centerData).map(c => {
          let center = this.$store.state.centerData[c]
          if(!stateOpts[center.state]) {
            stateOpts[center.state] = {
              title: states[center.state],
              values: []
            }
          }
          stateOpts[center.state].values.push({
            value: c,
            title: `${center.name}, ${center.state}`
          })
        })

        let locations = []
        Object.keys(stateOpts).map(s => {
          stateOpts[s].values.sort(this.sortOptions)
          locations.push(stateOpts[s])
        })
        locations.sort(this.sortOptions)

        f.options = locations
      }
      fields.push(f)
      // console.log(f.type)
      if (f.name === 'emailNotification') {
        model[f.name] = true
      } else if (f.name === 'smsNotification') {
        model[f.name] = false
      } else {
        model[f.name] = null
      }
    })

    return {
      charsRemaining: 256,
      calendar: null,
      fields: fields,
      model: model,
      errors: [],
      sending: false,
      complete: false,
      submitError: false,
      cardNumber: '',
      entryError: null,
      cards: [],
      valid: [],
      invalid: [],
    }
  },
  computed: {
    mobile() {
      const browser = detect()
      if(browser) {
        switch(browser.os) {
          case 'iOS':
          case 'Android OS':
            return true
            break
          default:
            return false
        }
      } else {
        return false
      }
    },
    season() {
      if (this.$route.name.includes('winter')) {
        return 'WINTER'
      } else {
        return 'SUMMER'
      }
    }
  },
  mounted() {
    if(this.defaults) {
      Object.keys(this.defaults).map(k => {
        this.model[k] = this.defaults[k]
      })
    }
    if (this.$route.query.barcode) {
      this.cardNumber = this.$route.query.barcode
    }
  },
  methods: {
    currentYear() {
      let year = moment().year()
      return year.toString()
    },
    async submitForm() {
      // this.complete = true
      this.submitError = false
      this.entryError = null
      if (this.cards.length < 1) {
        this.entryError = 'You must enter at least one Season Pass card number to register.'
        return
      }
      let errors = []
      this.fields.map(f => {
        if(!this.validate(f)) {
          errors.push(f.name)
        }
      })
      this.errors = errors

      if(errors.length < 1 && this.sending == false && this.complete == false) {
        const currentYear = this.currentYear();
        const currentYearLastDigits = currentYear.slice(2);
        let data = {
          type: `${this.season}`,
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          phoneNumber: this.model.phone.replace(/[- )(]/g,''),
          emailAddress: this.model.email,
          locationId: this.model.Location,
          passNumbers: this.cards,
          emailNotification: this.model.emailNotification ? true : false,
          smsNotification: this.model.smsNotification ? true : false,
          source: `${this.season.charAt(0)}SP${currentYear}`
        }

        this.sending = true
        let submissionData = await this.$mulesoftv3.registerSeasonPass(data)
        .then(res => {
          this.complete = true
          return res.data.passNumberStatus
        }).catch(err => {
          this.submitError = true
          console.error(err)
          return []
        })

        submissionData.map(card => {
          if (card.status === 'failure') {
            this.invalid.push(card)
          } else {
            this.valid.push(card)
          }
        })
        this.sending = false
      }
    },
    validate(f) {
      switch(f.type) {
        case 'tel':
          if(f.required || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[\(]?[1-9]{1}[0-9]{2}[\)\s\-\.]*[1-9]{1}[0-9]{2}[\s\-\.]?[0-9]{4}/)
          }
          break
        case 'email':
          return this.model[f.name] && this.model[f.name].match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          break
        case 'select':
          if((f.required && !f.showKey) || (f.required && f.showValue.indexOf(this.model[f.showKey]) > -1) || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[A-Za-z0-9]+/)
          }
        case 'text':
          if((f.required && !f.showKey) || (f.required && f.showValue.indexOf(this.model[f.showKey]) > -1) || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[A-Za-z0-9]+/)
          }
      }
      return true
    },
    addCard(){
      this.entryError = null
      if (!this.cardNumber.match(/^[0-9]+$/)) {
        this.entryError = 'Card numbers may only contain numbers 0-9'
      } else {
        this.cards.push(this.cardNumber)
        this.cardNumber = ''
      }
    },
    removeCard(card){
      let index = this.cards.indexOf(card)
      if (index > -1 && this.cards.length > 1) {
        let x = this.cards.splice(index, 1)
      } else {
        this.cards = []
      }
    },
    getWidth(f) {
      if(f.width) {
        if(f.width == 'full') {
          return 'w-full'
        } else if(f.width == 'auto') {
          return ''
        }
      }
      return 'w-full sm:w-1/2 sm:pr-2 sm:pr-8'
    },
    sortOptions(a, b) {
      if(a.title < b.title) {
        return -1
      } else if(a.title > b.title) {
        return 1
      } else {
        return 0
      }
    },
    updateSelect(name, value) {
      this.model[name] = value
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.locked {
  &, & > * {
    opacity: 1 !important;
  }
}
.contact.contact-bottom, .contact.bottom {
  margin-bottom: -5px;
}

.cards {
  background: #8a0ffe;
  background-image: linear-gradient(90deg,#8a0ffe,#d9117e);
}

.search {
  input {
    // max-width: 550px;
  }

  .button {
    margin-top: 0;
    border: 2px solid $purple;
    height: 50px;
    padding: 0 22px;
    font-size: 16px;
    cursor: pointer;
    margin-left: unset;
  }

  .button:disabled {
    border: 2px solid gray;
    color: gray;
  }
}

.confirmation-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;

  h1 {
    color: #3233FF;
  }

  .pass {
    background: #E0FFF9;
    color: #3233FF;
  }

  .success {
    color: #00C951;
  }

  .failure {
    color: #FF0000;
  }

  >div {
    max-height: 650px;
    overflow: auto;
  }
}
</style>
